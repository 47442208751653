import React from "react"
import Seo from "../components/Seo"
import Svg404 from "../contents/SVG/Svg404"
const NotFoundPage = () => {
  return (
    <div className="text-center">
      <Seo
        title="Not Found"
        description="404 Not Found"
        keywords=""
        lang="en"
      />
      <h1>404 Not Found</h1>
      <Svg404 className="max-w-full px-10 md:px-36" />
    </div>
  )
}
export default NotFoundPage
